import {db} from '../plugins/firebase'

// 指定した日付のfirestoreのデータを所得する
const getDataTargetDate = (date, mode) => {
  // 日付を取得
  const targetDateYear  = date.split("-")[0];
  const targetDateMonth = String(parseInt(date.split("-")[1], 10) -1);
  const targetDateDay   = date.split("-")[2];
  const targetDateStart = new Date(targetDateYear, targetDateMonth, targetDateDay, 0, 0, 0);
  const targetDateEnd   = new Date(targetDateYear, targetDateMonth, targetDateDay, 23, 59, 59);

  // クエリから日付分のデータを取得
  const firestoreData = db.collection(mode).where('time', '>=', targetDateStart).where('time', '<', targetDateEnd)

  return firestoreData
}

export default {getDataTargetDate};