<template>
  <v-tabs
    fixed-tabs
    background-color="light-blue"
    color="white"
    @change="onChangeMode($event)"
  >
    <v-tab>
      <v-icon>
        mdi-sack
      </v-icon>
    </v-tab>
    <v-tab>
      <v-icon>
        mdi-medal
      </v-icon>
    </v-tab>
  </v-tabs>
</template>

<script>
export default {
  data() {
    return{
      now_id: '',
      now_type: '',
      now_date: '',
      now_group: '',
      ids:['全動物'],
      groups:['全グループ'],
      animal: [],
      types: []
    }
  },
  methods: {
    onChangeMode(event) {
      this.$store.commit('updateMode', event);
    }
  }
}
</script>

<style scoped>

</style>