<template>
  <div class="select">
    <v-row
      class="mr-0"
    >
      <!-- <v-col 
        cols="7"
        sm="5"
        class="mt-2 mr-0"
      >
        <v-tabs
          fixed-tabs
          background-color="#E0E0E0"
          @change="onChangeMode($event)"
        > -->
          <!-- ゴミ -->
          <!-- <v-tab>
            <v-icon>
              mdi-sack
            </v-icon>
          </v-tab> -->
          <!-- GPS -->
          <!-- <v-tab>
            <v-icon>
              mdi-map-marker-multiple
            </v-icon>
          </v-tab> -->
          <!-- ポイント -->
          <!-- <v-tab>
            <v-icon>
              mdi-medal
            </v-icon>
          </v-tab> -->
        <!-- </v-tabs> -->
      <!-- </v-col> -->
      <!-- <v-spacer /> -->
      <v-col 
        cols="3" 
        md="3"
        class="pl-6 pt-2 text-h5">
        <v-menu v-model="menu" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="now_date" v-bind="attrs" v-on="on" readonly></v-text-field>
          </template>
          <v-date-picker v-model="now_date" @input="formatDate(now_date)" @change="onChangeDate($event)" locale="jp-ja" :day-format="(date) => new Date(date).getDate()" no-title>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col 
        cols="3" 
        md="3"
        class="pt-2">
        <v-select
          pt-0
          single-line
          v-model="now_type"
          :items="types"
          @change="onChangeType($event)"
        >
        </v-select>
      </v-col>
      <v-col 
        cols="3" 
        sm="3"
        class="pt-2">
        <v-select
          pt-0
          single-line
          v-model="now_group"
          :items="groups"
          @change="onChangeGroup($event)"
        >
        </v-select>
      </v-col>
      <v-col
        cols="3" 
        sm="3"
        class="pt-2">
        <v-select
          pt-0
          single-line
          v-model="now_id"
          :items="ids"
          @change="onChangeId($event)"
        >
        </v-select>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import garbage from '../../composables/garbage'
export default {
  data() {
    return{
      now_id: '',
      now_group: '',
      now_type: '',
      now_date: '',
      ids:['全動物'],
      animal: [],
      types: [],
      groups: [],
      menu: "",
      text: ""
    }
  },
  created() {
    this.animal    = this.$store.getters['animal'];
    this.ids       = this.ids.concat(this.animal);
    this.now_id    = this.$store.getters['id'];
    this.now_group = this.$store.getters['group'];
    this.groups    = this.$store.getters['groups'];
    this.now_type  = this.$store.getters['type'];
    this.types     = this.$store.getters['types'];
    this.now_date  = this.$store.getters['date'];

    this.params = new URLSearchParams(window.location.search);
    if ( this.params.has('user_id') ) {
      this.now_id = this.animal[this.params.get('user_id')];
      this.$store.commit('updateId', this.animal.indexOf(this.now_id));
    }
    if ( this.params.has('group') ) {
      this.now_group = this.params.get('group');
      this.$store.commit('updateGroup', this.now_group);
    }
    if ( this.params.has('date') ) {
      this.now_date = this.params.get('date');
      this.$store.commit('updateDate', this.now_date);
    }
    if ( this.params.has('type') ) {
      this.now_type = garbage.convertNameEnToJa[this.params.get('type')];
      if (this.now_type != 'other') {
        this.$store.commit('updateType', this.now_type);
      }
    }
    if ( this.params.has('table') ) {
      this.$store.commit('updateMode', 2);
    }
  },
  methods: {
    onChangeId(event) {
      if (event === '全動物'){
        this.$store.commit('updateId', event);
      } else {
        // All以外を選択の場合，animalリストからindexを取得
        const idOfIndex = this.animal.indexOf(event);
        this.$store.commit('updateId', idOfIndex);
      }
    },
    onChangeGroup(event) {
      this.$store.commit('updateGroup', event);
    },
    onChangeType(event) {
      this.$store.commit('updateType', event);
    },
    onChangeMode(event) {
      this.$store.commit('updateMode', event);
    },
    onChangeDate(event) {
      this.$store.commit('updateDate', event);
    },
    formatDate(date) {
      if (!date) return null;
        this.menu = false;
      return;
    }
  }
}
</script>

<style scoped>

.select{
  position: absolute;
  left: 0;
  top: 0px;
  width: 100%;
  height: 56px;
  background-color: #E0E0E0;
}

.v-select {
  font-size: 16px;
}

.v-input {
  font-size: 16px;
}

@media only screen and (max-width: 500px) {
  .v-select {
    font-size: 12px;
  }

  .v-input {
    font-size: 12px;
  }

  #selectdateform{
    font-size: 0.8rem;
  }
}
</style>