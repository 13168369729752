<template>
  <v-data-table
    :headers="headers"
    :items="data"
    :items-per-page="-1"
    style="width: 100%; height: calc(100% - 56px - 48px); position: absolute; overflow: scroll; left:0; top:56px;"
  ></v-data-table>
</template>

<script>
import firestore from '../../composables/firestore'
export default {
  data() {
    return {
      animal:[],
      flagGetData: false,
      headers: [
        {
          text: 'ID',
          sortable: false,
          value: 'id',
        },
        { text: 'Group', value: 'group_id' },
        { text: 'User', value: 'user_id' },
        { text: 'Latitude', value: 'latitude' },
        { text: 'Longitude', value: 'longitude' },
        { text: 'Type', value: 'type' },
        { text: 'Time', value: 'time' },
      ],
      data: []
    }
    
  },
  created() {
    this.animal = this.$store.getters['animal'];
    this.group  = this.$store.getters['group'];
  },
  mounted() {
    this.showData(this.nowDate, this.nowType, this.nowGroup);
  },
  computed: {
    nowDate() {
      return this.$store.getters['date'];
    },
    nowType() {
      return this.$store.getters['type'];
    },
    nowGroup() {
      return this.$store.getters['group'];
    },
  },
  watch: {
    nowDate(val) {
      this.showData(val, this.nowType, this.nowGroup);
    },
    nowType(val) {
      this.showData(this.nowDate, val, this.nowGroup);
    },
    nowGroup(val) {
      this.showData(this.nowDate, this.nowType, val);
    },
  },
  methods: {
    showData(date, type, group) {
      this.data = []
      const modeName = 'garbage';
      let firestoreData = firestore.getDataTargetDate(date, modeName);
      if (group != '全グループ') {
        group = Number(group);
        firestoreData = firestoreData.where('group_id', '==', group);
      }
      firestoreData.get().then(docs => {
        if (docs.size == 0) {
          console.log("データがありません");
        } else {
          docs.forEach((doc) => {
            let newData = {};
            newData['id'] = doc.id;
            newData['group_id'] = doc.data().group_id;
            newData['user_id'] = doc.data().user_id;
            newData['latitude'] = doc.data().latitude;
            newData['longitude'] = doc.data().longitude;
            newData['type'] = doc.data().type;
            newData['time'] = this.getStringFromDate(doc.data().time);
            this.data.push(newData);
          })
        }
      })
    },
    getStringFromDate(date) {
      const dateTime = new Date(date.seconds * 1000);
      const outputStringDate = dateTime.toLocaleDateString() + ' ' + dateTime.toLocaleTimeString();
      return outputStringDate;
    }
  }
}

</script>