import Vue from 'vue'
// import VueFire from 'vuefire'
import { firestorePlugin } from 'vuefire';
import firebase from 'firebase/app';
import 'firebase/firestore';

Vue.use(firestorePlugin)
// Vue.use(VueFire)

const firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyDb2BCe1ULMDVg4SK6TDgyzBqQkQ2M7OPU",
  authDomain: "cicptongs-a1b76.firebaseapp.com",
  projectId: "cicptongs-a1b76",
  storageBucket: "cicptongs-a1b76.appspot.com",
  messagingSenderId: "687458868015",
  appId: "1:687458868015:web:7fb11391d45f234c091fda"
});


export const db = firebaseApp.firestore();