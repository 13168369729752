<template>
  <div class="list" style="width: 100%; height: calc(100% - 56px - 48px); position: absolute; overflow: scroll; left:0; top:56px;">
    <ul v-if="flagGetData === true">
      <li v-for="(rank, index) in topRank" v-bind:key="rank.id">
        <v-card
          outlined
          class="mt-4 mb-4"
        >
          <v-card-text class="rank">
            <div class="rank-num one" v-if="index  === 0">1</div>
            <div class="rank-num two" v-else-if="index === 1">2</div>
            <div class="rank-num three" v-else-if="index === 2">3</div>
            <div class="rank-num other" v-else>{{index + 1}}</div>
            <div class="rank-info">
              <div class="rank-info-name">
                {{ rank.user }}
              </div>
              <div class="rank-info-point">
                {{ rank.point }} pt
              </div>
            </div>
          </v-card-text>
        </v-card>
      </li>
    </ul>
    <div class="no_data" v-if="flagGetData === false">データがありません😢</div>
  </div>
</template>

<script>
import firestore from '../../composables/firestore'
import garbage from '../../composables/garbage'
export default {
  data() {
    return {
      topRank: [
        {user: null, point: 0},
        {user: null, point: 0},
        {user: null, point: 0},
        {user: null, point: 0},
        {user: null, point: 0},
        {user: null, point: 0},
        {user: null, point: 0},
        {user: null, point: 0},
        {user: null, point: 0},
        {user: null, point: 0},
        {user: null, point: 0},
        {user: null, point: 0},
        {user: null, point: 0},
        {user: null, point: 0},
        {user: null, point: 0}
      ],
      animal:[],
      flagGetData: false,
      // scores: Array(21).fill(0) 
    }
    
  },
  created() {
    this.animal = this.$store.getters['animal'];
    this.group  = this.$store.getters['group'];
  },
  mounted() {
    this.sortData(this.nowDate, this.nowType, this.nowGroup);
  },
  computed: {
    nowDate() {
      return this.$store.getters['date'];
    },
    nowType() {
      return this.$store.getters['type'];
    },
    nowGroup() {
      return this.$store.getters['group'];
    },
  },
  watch: {
    nowDate(val) {
      this.sortData(val, this.nowType, this.nowGroup);
    },
    nowType(val) {
      this.sortData(this.nowDate, val, this.nowGroup);
    },
    nowGroup(val) {
      this.sortData(this.nowDate, this.nowType, val);
    },
  },
  methods: {
    getTopData: async function(strDate, type, group){
      const scores = this.createAssArray(20);
      let firestoreData = firestore.getDataTargetDate(strDate, 'garbage');

      if (type != 'ごみ全種') {
        firestoreData = firestoreData.where('type', '==', garbage.convertNameJaToEn[type]);
      }

      if (group != '全グループ') {
        group = Number(group);
        firestoreData = firestoreData.where('group_id', '==', group);
      }

      await firestoreData.get().then(docs => {
        if (docs.size == 0) {
          this.flagGetData = false;
          console.log("データがありません");
        } else {
          docs.forEach((doc) => {
            let type = ''
            if (doc.data().annotation_type == undefined) {
              type = doc.data().type
            } else {
              type = this.findMostAnnotationType(doc.data())
            }
            // annotation_typeが'none'の場合は、マップ上に表示させない
            if (type == 'none') { return; }
            const nowUserId = doc.data().user_id
            scores[nowUserId] += 1;
          })
        }
      }) 
      
      return scores
    },
    sortData: async function(strDate, type, group) {
      const nowScores = await this.getTopData(strDate, type, group)
      //キーを含んだ配列に変換 オブジェクト->配列
      const array = Object.keys(nowScores).map((k)=>(
        { key: k, value: nowScores[k] }
      ));    
      //多い順
      array.sort((a, b) => b.value - a.value);
      for(let i=0; i<15; i++){
        this.topRank[i]['user'] = this.animal[array[i]['key']]
        this.topRank[i]['point'] = array[i]['value']
      }

    },

    createAssArray(count) {
      this.flagGetData = true;
      const srcArray = []
      for(let i=0; i<=count; i++)
      {  
        srcArray[i] = 0;
      }
      return srcArray
    },

    findMostAnnotationType(data) {
      const countMap = {};
      // リスト内の文字列をカウントする
      for (const annotationType of data.annotation_type) {
        let str = annotationType.type
        if (countMap[str]) {
          countMap[str]++;
        } else {
          countMap[str] = 1;
        }
      }
      let maxCount = 0;
      let mostFrequentString = '';
      // カウント数が最も多い文字列を見つける
      for (const str in countMap) {
        if (countMap[str] > maxCount) {
          maxCount = countMap[str];
          mostFrequentString = str;
        }
      }
      return mostFrequentString;
    }
  }
}
</script>

<style>
.v-application ul{
  list-style-type: none;
  padding-left: 0px;
}

.rank{
  /* position: relative; */
  display: flex;
  flex-wrap: wrap;
  /* justify-content: center; */
  align-items: center;
  margin: 36px 0;
}

.rank-num{
  position: absolute;
  left: 15%;
  font-weight: bold;
  font-size: 60px;
  margin-right: 24px;
}

.rank-info{
  position: absolute;
  left: 40%;
}

.rank-info-name{
  font-size: 32px;
  margin-bottom: 16px;
  font-weight: bold;
}

@media only screen and (max-width: 500px) {
  .rank-info-name{
    font-size: 20px;
    margin-bottom: 16px;
    font-weight: bold;
  }
}


.rank-info-point{
  font-size: 24px;
  text-align: left;
  margin-top: 16px;
  margin-left: 4px;
}

.one{
  color: #CAA846;
}

.two{
  color: #BDC3C9;
}

.three{
  color: #BA6E40;
}

.other{
  color: rgb(24, 128, 226);
}

.no_data{
  margin: 80px 0;
  text-align: center;
  font-size: 40px;
  font-weight: bold;
}
</style>