<template>
  <div class="main_container">
    <div class="map_container" v-if="nowMode === 0">
      <div class="map" >
        <l-map ref="map" style="width: 100%; height: calc(100% - 56px - 48px);  position: absolute; left:0; top:56px; z-index: 0;" :zoom="12" :options="{ maxNativeZoom: 18, maxZoom: 21, zoomControl: false }" :center="center">
          <l-tile-layer :url="url" :options="{ maxNativeZoom: 18, maxZoom: 21 }"></l-tile-layer>
          <l-control-zoom position="bottomright"></l-control-zoom>
        </l-map>
        <v-navigation-drawer
          v-model="markerInfo"
          clipped-left
          absolute
          temporary
        >
          <div v-if="garbageInfos.length > 1">
            <v-list>
              <v-list-group v-for="info in garbageInfos" :key="info.time">
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title>{{ info.name }}</v-list-item-title>
                  </v-list-item-content>
                </template>
                <v-list-item>
                  <v-list-item-content>
                    <div class="infolist-img">
                      <img v-bind:src="info.img" width="256px" height="256px">
                    </div>
                    <div class="infolist">
                      <div class="infolist-name">{{ info.name }}</div>
                      <div class="infolist-section">
                        <div class="infolist-section-title">Time</div>
                        <div class="infolist-section-content">{{ info.time }}</div>
                      </div>
                      <div class="infolist-section">
                        <div class="infolist-section-title">ID</div>
                        <div class="infolist-section-content">{{ info.userId }}</div>
                      </div>
                      <div class="infolist-section">
                        <div class="infolist-section-title">Area</div>
                        <div class="infolist-section-content">Lat: {{ info.pos.lat }}</div>
                        <div class="infolist-section-content">Lng: {{ info.pos.lng }}</div>
                      </div>
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-list-group>
            </v-list>
          </div>
          <div v-else>
            <div class="infolist-img">
              <img v-bind:src="garbageInfos[0].img" width="256px" height="256px">
            </div>
            <div class="infolist">
              <div class="infolist-name">{{ garbageInfos[0].name }}</div>
              <div class="infolist-section">
                <div class="infolist-section-title">Time</div>
                <div class="infolist-section-content">{{ garbageInfos[0].time }}</div>
              </div>
              <div class="infolist-section">
                <div class="infolist-section-title">ID</div>
                <div class="infolist-section-content">{{ garbageInfos[0].userId }}</div>
              </div>
              <div class="infolist-section">
                <div class="infolist-section-title">Area</div>
                <div class="infolist-section-content">Lat: {{ garbageInfos[0].pos.lat }}</div>
                <div class="infolist-section-content">Lng: {{ garbageInfos[0].pos.lng }}</div>
              </div>
            </div>
          </div>
        </v-navigation-drawer>
      </div>

      <div class="point">
        <div class="point-desc">現在のポイント</div>
        <span class="point-count">
          <em class="point-count-num"> {{userPoint}} </em>
          <em class="point-count-unit"> pt</em>
        </span>
      </div>

      <!-- 凡例 -->
      <div class="garbage" v-if="showGarbageGuide">
        <div class="guide">
          <div class="guide-content">
            <div class="guide-content-flex">
              <div class="marker">
                <div class="marker-circle can"></div>
                <div class="marker-name">缶</div>
              </div>
              <div class="marker">
                <div class="marker-circle petbottle"></div>
                <div class="marker-name">ペットボトル</div>
              </div>
              <div class="marker">
                <div class="marker-circle tobacco"></div>
                <div class="marker-name">タバコ</div>
              </div>
              <div class="marker">
                <div class="marker-circle paper"></div>
                <div class="marker-name">紙類</div>
              </div>
              <!-- <div class="marker">
                <div class="marker-circle plastic"></div>
                <div class="marker-name">プラスチック</div>
              </div> -->
              <div class="marker">
                <div class="marker-circle other"></div>
                <div class="marker-name">その他</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="garbage" v-if="showGPSGuide">
        <div class="guide">
          <div class="guide-content">
            <div class="guide-content-flex" v-for="(color, index) in gpsMarkerColorPalette" v-bind:key="color.id">
              <div class="marker-gps">
                <div class="marker-circle" v-bind:style="{ background: color }"></div>
                <div class="marker-name">{{ index }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="nowMode === 1">
      <Rank />
    </div>
    <div v-if="nowMode === 2">
      <Table />
    </div>
  </div>
</template>
<script>
import Rank from './Rank.vue'
import Table from './Table.vue'
import firestore from '../../composables/firestore'
import garbage from '../../composables/garbage'
import {LMap, LTileLayer, LControlZoom} from 'vue2-leaflet';
import L from 'leaflet';
export default {
  components: {
    Rank,
    Table,
    LMap,
    LTileLayer,
    LControlZoom
  },
  data() {
    return {
      center: { lat: 34.6873424, lng: 135.6895802 },
      infoContent: '',
      infoWindowPos: {
        lat: 0,
        lng: 0
      },
      infoWindowOpen: false,
      currentMidx: null,
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: 0
        }
      },
      markers: [],
      animal: [],
      markerInfo: false,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      userPoint: 0,
      showGarbageGuide: true,
      showGPSGuide: false,
      gpsMarkerColorPalette: ["#004D40", "#9CCC65", "#41A1E1", "#A667BF", "#F2C500", "#EA8B1D", "#EB5D49", "#475B6F", "#ECF0F1", "#9FAEAF"],
      garbages: {},
      garbageInfos: [      
        {
          name: null,
          className: null,  // css指定時に使用
          img: null,
          time: null,
          userId: null,
          pos: {
            lat: null,
            lng: null
          }
        },
      ]
    };
  },
  created() {
    this.animal = this.$store.getters['animal'];
  },
  mounted() {
    this.showMapData(this.nowId, this.nowGroup, this.nowType, this.nowMode, this.nowDate);
    this.showGuide(this.nowMode);
  },
  computed: {
    nowId() {
      return this.$store.getters['id'];
    },
    nowGroup() {
      return this.$store.getters['group'];
    },
    nowType() {
      return this.$store.getters['type'];
    },
    nowMode() {
      return this.$store.getters['mode'];
    }, 
    nowDate() {
      return this.$store.getters['date'];
    }
  },
  watch: {
    nowId(val) {
      if (this.nowMode === 0) { 
        this.resetMarker();
        this.showMapData(val, this.nowGroup, this.nowType, this.nowMode, this.nowDate);
      }
    },
    nowGroup(val) {
      if (this.nowMode === 0) { 
        this.resetMarker();
        this.showMapData(this.nowId, val, this.nowType, this.nowMode, this.nowDate);
      }
    },
    nowType(val) {
      if (this.nowMode === 0) { 
        this.resetMarker();
        this.showMapData(this.nowId, this.nowGroup, val, this.nowMode, this.nowDate);
      }
    },
    nowMode(val) {
      if (val === 0) {
        this.showMapData(this.nowId, this.nowGroup, this.nowType, val, this.nowDate);
        this.showGuide(val);
      }
    },
    nowDate(val) {
      if (this.nowMode === 0) { 
        this.resetMarker();
        this.showMapData(this.nowId, this.nowGroup, this.nowType, this.nowMode, val);
      }
    }
  },
  methods: {
    resetMarker() {
      let map = this.$refs.map.mapObject;
      this.markers.forEach(m => {
        map.removeLayer(m);
      });
      this.markers = [];
    },

    showGuide(mode) {
      if (mode == 0){
        this.showGPSGuide = false;
        this.showGarbageGuide = true;
      } else if (mode == 1){
        this.showGarbageGuide = false;
        this.showGPSGuide = true;
      }
    },

    showMapData(id, group, type, mode, strDate) {
      const modeName = 'garbage';
      let firestoreData = firestore.getDataTargetDate(strDate, modeName);
      if (id != '全動物') {
        firestoreData = firestoreData.where('user_id', '==', id);
      }
      if (group != '全グループ') {
        group = Number(group);
        firestoreData = firestoreData.where('group_id', '==', group);
      }
      const garbagePos = [];
      firestoreData.get().then(docs => { 
        if (docs.size == 0) {
          console.log("データがありません");
        } else {
          const map = this.$refs.map.mapObject;
          this.userPoint = 0;
          this.garbages = {}
          docs.forEach((doc) => {
            if (doc.data().latitude != null && doc.data().longitude != null){
              let markerLatitude = doc.data().latitude;
              let markerLongitude = doc.data().longitude;
              let markerType = ''
              if (doc.data().annotation_type == null) {
                markerType = doc.data().type
              } else {
                markerType = this.findMostAnnotationType(doc.data())
              }

              // ごみ種別表示で、認識結果とアノテーション結果が異なる場合は、マップ上に表示させない
              // if (type != 'ごみ全種' && markerType != doc.data().type) { return; }
              // 指定されたごみの種別ではないごみは、マップ上に表示させない
              if (type != 'ごみ全種' && markerType != garbage.convertNameJaToEn[type]) { return; }
              // annotation_typeが'none'の場合は、マップ上に表示させない
              if (markerType == 'none') { return; }

              this.userPoint += 1;
              if (mode == 0) {
                let mapKey = String(markerLatitude) + "," + String(markerLongitude);
                if (!Object.prototype.hasOwnProperty.call(this.garbages, mapKey)) {
                  this.garbages[mapKey] = [];
                }
                let pushObject = {
                  name: garbage.convertNameEnToJa[markerType],
                  className: garbage.convertNameEnToClass[markerType],
                  img: doc.data().img_url,
                  time: this.getStringFromDate(doc.data().time),
                  userId: this.animal[doc.data().user_id],
                  pos: {
                    lat: doc.data().latitude,
                    lng: doc.data().longitude
                  }
                }
                this.garbages[mapKey].push(pushObject);
              }
            }
          });
          this.setMapPlot(map, this.garbages, garbagePos);
          this.fitBoudns(map, garbagePos);
        }
      }).catch((error) => {
        console.log(error);
      });
    },

    // 緯度経度に紐づいたgarbage情報を地図上に表示する
    // garbages => 複数のgarbage
    // garbage[0] => 緯度 + 経度 (文字列)
    // garbege[1] => garbage情報（配列）
    setMapPlot(map, garbages, pos) {
      Object.entries(garbages).forEach((garbage) => {
        let coordinate = garbage[0].split(',');
        let markerLatitude = parseFloat(coordinate[0]);
        let markerLongitude = parseFloat(coordinate[1]);
        let marker = L.circleMarker;
        let iconName = garbage[1][0].className;
        if (garbage[1].length === 1) {
          let singleIcon = L.divIcon({
            className: 'circle-with-txt' + ' ' + iconName,
            iconSize: [24, 24]
          });
          marker = L.marker([markerLatitude, markerLongitude], {
            icon: singleIcon
          }).addTo(map)
          marker.on('click', () => {
            this.showClickInfo(garbage[0])
          });
        } else {
          let sameCount = garbage[1].length
          let multiIcon = L.divIcon({
            html: '<div>' + sameCount + '</div>',
            className: 'circle-with-txt' + ' ' + iconName,
            iconSize: [24, 24]
          });
          let count = L.marker([markerLatitude, markerLongitude], {
            icon: multiIcon
          }).addTo(map);
          count.on('click', () => {
            this.showClickInfo(garbage[0]);
          });
          this.markers.push(count);
        }
        this.markers.push(marker);
        pos.push(
          {position: {lat: markerLatitude, lng: markerLongitude}}
        );
      });
    },

    fitBoudns(map, pos) {
      if (pos.length > 0) {
        const cornerSWLat = Math.min(...pos.map(d => d.position.lat));
        const cornerSWLng = Math.min(...pos.map(d => d.position.lng));
        const cornerNELat = Math.max(...pos.map(d => d.position.lat));
        const cornerNELng = Math.max(...pos.map(d => d.position.lng));
        const latLngBounds = [[cornerSWLat, cornerSWLng],[cornerNELat, cornerNELng]];
        map.fitBounds(latLngBounds);
      }
    },

    showClickInfo(key) {
      this.garbageInfos = [];
      this.markerInfo = true;
      this.garbageInfos = this.garbages[key];
    },

    getStringFromDate(date) {
      const dateTime = new Date(date.seconds * 1000);
      const outputStringDate = dateTime.toLocaleDateString() + ' ' + dateTime.toLocaleTimeString();
      return outputStringDate;
    },
    findMostAnnotationType(data) {
      const countMap = {};
      // リスト内の文字列をカウントする
      for (const annotationType of data.annotation_type) {
        const str = annotationType.type
        if (countMap[str]) {
          countMap[str]++;
        } else {
          countMap[str] = 1;
        }
      }
      let maxCount = 0;
      let mostFrequentString = '';
      // カウント数が最も多い文字列を見つける
      for (const str in countMap) {
        if (countMap[str] > maxCount) {
          maxCount = countMap[str];
          mostFrequentString = str;
        }
      }
      return mostFrequentString;
    }
  },
};
</script>

<style>
.point {
  position: absolute;
  right: 0px;
  top: 68px;
  padding: 8px 10px 0px;
  width: 120px;
  border-radius: 8px 0 0 8px;
  background-color: white;
  box-shadow: 0 0 4px gray;
}

.point-desc {
  font-size: 12px;
}

.point-count {
  justify-content: center;  
  vertical-align:baseline;
}

.point-count-num {
  font-size: 28px;
  font-weight: bold;
  color: #D04255;
}


.guide {
  position: absolute;
  right: 0px;
  top: 172px;
  padding: 12px 0;
  width: 72px;
  border-radius: 8px 0 0 8px;
  background-color: white;
  box-shadow: 0 0 4px gray;
}

.guide-content-flex{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}

.marker{
  margin: 12px auto;
}

.marker-gps{
  margin: 4px auto;
}

.marker-circle{
  margin: 0 auto;
  margin-bottom: 4px;
  width: 14px;
  height: 14px;
  opacity: 0.8;
  border-radius: 50px;
  border: solid 1px white;
  box-shadow: 0px 0px 2px gray;
}

.infolist{
  margin: 0 20px;
  text-align: left;
}

.infolist-name{
  font-weight: 900;
  font-size: 32px;
  margin-top: 16px;
  margin-bottom: 12px;
}

.infolist-section{
  margin-bottom: 20px;
}

.infolist-section-title{
  font-size: 20px;
  font-weight: bold;
}

.can{
  background-color: #C7243A;
}
.petbottle{
  background-color: #3261AB;
}
.tobacco{
  background-color: #EDAD0B;
}
.paper{
  background-color: #009250;
}
.plastic{
  background-color: #9C1276;
}

.other{
  background-color: #131420;
}

.marker-name{
  font-weight: bold;
  font-size: 10px;
}

.circle-with-txt {
  color: white;
  font-size: 12px;
  font-weight: bold;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  padding-top: 2px;
  opacity: 0.85;
  border: 1px solid white; 
}

.v-list {
  padding: 0;
}

.v-list-item {
  padding: 0 4px;
}
</style>