import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex)

const now   = new Date();
const year  = now.getFullYear();
const month = now.getMonth() + 1;
const day   = now.getDate();
const today = year + "-" + month + "-" + day;

export default new Vuex.Store({
  state: {
    id: '全動物',
    animal: ["🐶 イヌ", "🐱 ネコ", "🐰 ウサギ", "🐼 パンダ", "🐧 ペンギン", "🐹 ハムスター", "🐬 イルカ", "🐿 リス", "🐨 コアラ", "🦒 キリン", "🐘 ゾウ", "🐢 カメ", "🦦 ラッコ", "🦁 ライオン", "🐏 ヒツジ", "🦙 アルパカ", "🐻 クマ", "🐳 クジラ", "🐵 サル", "🦘 カンガルー", "🐭ネズミ", "🦊キツネ", "🐮ウシ", "🐴ウマ", "🦌シカ"],
    group: '全グループ',
    groups: [ '全グループ', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
    type: 'ごみ全種',
    types: ['ごみ全種', '缶', 'ペットボトル', 'タバコ', '紙類', 'プラスチック'],
    mode: 0,  // 0: garbage, 1: GPS
    date: '',
    garbages: {}
  },
  mutations: {
    updateId(state, payload){
      state.id = payload;
    },
    updateGroup(state, payload){
      state.group = payload;
    },
    updateType(state, payload){
      state.type = payload;
    },
    updateMode(state, payload){
      state.mode = payload
    },
    updateDate(state, payload){
      state.date = payload
    },
    updateGarbages(state, payload){
      state.garbages = payload
    }
  },
  getters: {
    mode(state){
      return state.mode;
    },
    id(state){
      return state.id;
    },
    animal(state){
      return state.animal;
    },
    group(state){
      return state.group;
    },
    groups(state){
      return state.groups;
    },
    type(state){
      return state.type;
    },
    types(state){
      return state.types;
    },
    date(state){
      if (state.date === ''){
        // 起動時は最新日のデータを表示
        state.date = today;
      }
      return state.date;
    },
    garbages(state){
      return state.garbages;
    }
  }
})