// jaName -> enName
const convertNameJaToEn = {
  '缶' : 'can',
  'ペットボトル': 'PET bottle',
  'タバコ' : 'tobacco',
  '紙類' : 'paper',
  'プラスチック' : 'plastic',
  'その他' : 'other'
};


// enName -> jaName
const convertNameEnToJa = {
  'can' : '缶',
  'PET bottle' : 'ペットボトル',
  'tobacco' : 'タバコ',
  'tabacco' : 'タバコ',
  'paper' : '紙類',
  'plastic' : 'プラスチック',
  'other' : 'その他'
};

const convertNameEnToClass = {
  'can' : 'can',
  'PET bottle' : 'petbottle',
  'tobacco' : 'tobacco',
  'tabacco' : 'tobacco',
  'paper' : 'paper',
  'plastic' : 'plastic',
  'other' : 'other'
};

export default {convertNameJaToEn, convertNameEnToJa, convertNameEnToClass};